
import moment from "moment";
//import {translator} from "@/lib/translator";

moment.locale('cs');
export default {

    methods:{
        logout(){
            axios.get( "/logout?t="+ new Date().getTime()).then(result => {
                if (result.status == 200) {
                 //   this.$store.commit('setuser', false)
                //    this.$router.push('/')
                    localStorage.setItem('user-token', null)
                    axios.defaults.headers.common['Authorization'] = ''
                }
            })
        },


        date(value) {
            value = moment(value).format('DD. MM. YYYY')
            return value
        },

        dateTime(value) {
            if (value==null) return ""
            value = moment(value).format('DD. MM. YYYY, H:mm')
            return value
        },

        fromNow(value){
            return  moment(value).fromNow();
        },
        defaultAvatar(url){
            return url ? url : '/Login.png'
        }


    },
}
